<template>
  <FormSelect
    label="Store"
    :values="values"
    :options="options"
    :rules="rules"
    :loading="loading"
    :disabled="loading"
    :multiple="multiple"
    :rawData="rawData"
    :emit-value="!rawData"
    :map-options="!rawData"
    @updateStore="onUpdateStore"
  />
</template>

<script>
import { ref, computed, inject, watch, onBeforeMount } from 'vue'
import { FormSelect } from '@/components/inputs'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'SelectStores',
  inheritAttrs: true,
  emits: ['updateStore', 'getOptions'],
  components: {
    FormSelect
  },
  props: {
    values: {
      type: [String, Number, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    },
    withAll: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { data, error, loading, post } = fetchData()
    const { showToast } = Toast()
    const state = inject('store')?.state
    const options = ref([])
    const rules = computed(() => {
      return props.multiple ? [VALIDATION.requiredArray] : [VALIDATION.required]
    })

    const onUpdateStore = data => {
      emit('updateStore', data)
    }

    const getStores = () => {
      const payload = {
        filters: {
          retailer: state?.profile?.retailer_id
        }
      }
      post('v1/stores', payload)
    }

    onBeforeMount(() => {
      getStores()

      watch([data, error, loading], () => {
        if (!loading.value) {
          if (error?.value) {
            showToast('There was a problem fetching stores.', 'danger')
          } else if (data?.value) {
            options.value = data?.value?.map(item => ({
              label: item?.name,
              value: item?.id
            }))

            if (props.withAll) {
              options.value.unshift({
                label: 'All',
                value: '0'
              })
            }

            emit('getOptions', options.value)
          }
        }
      })
    })

    return { options, rules, loading, VALIDATION, onUpdateStore }
  }
}
</script>
