<template>
  <FormSelect
    label="Sub Category"
    :values="values"
    :options="options"
    :rules="[VALIDATION.required]"
    :loading="loading"
    :disabled="loading"
    :multiple="multiple"
    @updateStore="onUpdateStore"
  />
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import { FormSelect } from '@/components/inputs'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'SelectSubCategories',
  inheritAttrs: true,
  emits: ['updateStore'],
  components: {
    FormSelect
  },
  props: {
    values: {
      type: [String, Number, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    withAll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { data, error, loading, get } = fetchData()
    const { showToast } = Toast()
    const options = ref([])

    const onUpdateStore = data => {
      emit('updateStore', data)
    }

    onBeforeMount(() => {
      get('v1/product-hierarchy/2')

      watch([data, error, loading], () => {
        if (!loading.value) {
          if (error?.value) {
            showToast('There was a problem fetching categories.', 'danger')
          } else if (data?.value) {
            options.value = data?.value?.map(item => ({
              label: item?.name,
              value: item?.id
            }))

            if (props.withAll) {
              options.value.unshift({
                label: 'All',
                value: 'all'
              })
            }
          }
        }
      })
    })

    return { options, loading, VALIDATION, onUpdateStore }
  }
}
</script>
