<template>
  <FormSelect
    label="Customer Segment"
    :values="values"
    :options="options"
    :rules="[VALIDATION.required]"
    :loading="loading"
    :disabled="loading"
    :multiple="multiple"
    :rawData="rawData"
    :emit-value="!rawData"
    :map-options="!rawData"
    :retailer="retailer"
    @updateStore="onUpdateSegment"
  />
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import { FormSelect } from '@/components/inputs'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'SelectSegments',
  inheritAttrs: true,
  emits: ['updateStore'],
  components: {
    FormSelect
  },
  props: {
    values: {
      type: [String, Number, Array],
      default: null
    },
    retailer: {
      type: [String, Number],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    withAll: {
      type: Boolean,
      default: false
    },
    withRetailer: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { data, error, loading, get } = fetchData()
    const { showToast } = Toast()
    const options = ref([])

    const onUpdateSegment = data => {
      emit('updateSegment', data)
    }

    const getSegments = () => {
      if (props.retailer) {
        get(`v1/customer-segmentation/filtered?retailer_id=${props.retailer}`)
      } else if (!props.withRetailer) {
        get(`v1/customer-segmentation/filtered`)
      } else {
        options.value = []
      }
    }

    watch(
      () => props.retailer,
      () => {
        getSegments()
      }
    )

    onBeforeMount(() => {
      getSegments()

      watch([data, error, loading], () => {
        if (!loading.value) {
          if (error?.value) {
            showToast(
              error?.value?.errors
                ? error?.value?.errors[0]?.msg
                : error?.value?.message ??
                    'There was a problem fetching segments.',
              'danger'
            )
          } else if (data?.value) {
            options.value = data?.value
              ?.filter(item => item?.status === 'A')
              .map(item => ({
                label: `${item?.code} - ${item?.name}`,
                value: item?.id,
                description: item?.remarks
              }))

            if (props.withAll) {
              options.value.unshift({
                label: 'All Unilever Customers',
                value: 'all_principal'
              })
              options.value.unshift({
                label: 'All Customers',
                value: 'all'
              })
            }
          }
        }
      })
    })

    return { options, loading, VALIDATION, onUpdateSegment }
  }
}
</script>
