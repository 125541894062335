<template>
  <FormSelect
    label="SKU"
    :values="values"
    :options="options"
    :rules="rules"
    :loading="loading"
    :multiple="multiple"
    :emit-value="!rawData"
    :rawData="rawData"
    input-debounce="1000"
    @updateStore="onUpdateStore"
    @customFilter="getProducts"
    placeholder="Search SKU desc"
    hint="Search SKU (minimum of 3 characters)"
  />
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { FormSelect } from '@/components/inputs'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'SelectProducts',
  inheritAttrs: true,
  emits: ['updateStore'],
  components: {
    FormSelect
  },
  props: {
    values: {
      type: [String, Number, Array, Object],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { data, error, loading, get } = fetchData()
    const { showToast } = Toast()
    const options = ref([])
    const rules = computed(() => {
      return props.multiple ? [VALIDATION.requiredArray] : [VALIDATION.required]
    })

    const onUpdateStore = data => {
      emit('updateStore', data)
    }

    const getProducts = data => {
      if (data) {
        if (data.length < 3) {
          return
        }
        get('v1/products/' + data)
      }
    }

    onBeforeMount(() => {
      watch([data, error, loading], () => {
        if (!loading.value) {
          if (error?.value) {
            showToast('There was a problem fetching products.', 'danger')
          } else if (data?.value) {
            options.value = data?.value?.map(item => ({
              label: item?.sku_desc,
              value: item?.id,
              description: `SKU: ${item?.id}`
            }))
          }
        }
      })
    })

    return { options, rules, loading, VALIDATION, onUpdateStore, getProducts }
  }
}
</script>
